import * as React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import EventYangDiikuti from "../../components/Dashboard/EventYangDiikuti"

const DashboardEventsPage = () => {
  return (
    <Layout>
      <SEO title="Event yang diikuti" />
      <EventYangDiikuti />
    </Layout>
  )
}

export default DashboardEventsPage
