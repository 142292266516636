// Generic imports
import React, { useState } from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

// Other components imports
import { Link } from "gatsby"

const Styles = styled.div`
  padding: 12px;
  max-width: 100%;

  & > div:first-child {
    display: flex;
    overflow-x: auto;
    padding-bottom: 3px;
  }

  & > div:first-child > a {
    margin: auto 10px;
    margin-right: 20px;
    border-bottom: 4px solid transparent;
    color: black;
    padding-top: 4px;
    padding-bottom: 4px;
    min-width: max-content;
  }

  & > div:first-child > .active {
    border-bottom: 4px solid #f4b71a;
    font-weight: bold;
    color: #f4b71a;
  }

  & > .smallnav-border-gray {
    padding-top: 1px;
    border-bottom: 1px solid #cacccf;
  }

  a:hover {
    text-decoration: none;
  }
`

// navList in Array, chosen as navList index
export default function Smallnav(props) {
  const { onToggle, navList, chosen, children, link } = props

  let navText = navList

  if (link) {
    navText = []
    navList.map(item => navText.push(item.text))
  }

  const [active, setActive] = useState(
    chosen !== undefined ? navText[chosen] : navText[0]
  )

  const toggleHead = id => {
    setActive(id)

    if (onToggle !== undefined) onToggle(id)
  }

  return (
    <Styles className="asd-smallnav" {...props}>
      <div>
        {link !== true
          ? navList.map((val, idx) => {
              // ANCHOR
              return (
                <a
                  key={idx}
                  className={active === val ? "active" : ""}
                  onClick={() => toggleHead(val)}
                >
                  {val}
                </a>
              )
            })
          : navList.map((item, idx) => {
              // GATSBY LINK
              return (
                <Link
                  key={idx}
                  className={active === item.text ? "active" : ""}
                  to={item.url}
                  state={{ chosen: item.text }}
                >
                  {item.text}
                </Link>
              )
            })}
      </div>
      <div className="smallnav-border-gray" />
      <div>{children}</div>
    </Styles>
  )
}

Smallnav.propTypes = {
  navList: PropTypes.array,
  link: PropTypes.bool,
}
