import React from "react"
import styled from "styled-components"

import Smallnav from "../Reusable/Smallnav"

const DashboardStyles = styled.div`
  padding-top: 40px;

  .asd-smallnav > div:first-child {
    justify-content: center;

    a {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .asd-smallnav > .smallnav-border-gray {
    border-bottom: none;
  }

  @media only screen and (max-width: 700px) {
    .asd-smallnav > div:first-child {
      justify-content: start;
    }
  }
`

function DashboardComponent({ chosen, children }) {
  return (
    <DashboardStyles className="layout">
      <Smallnav
        navList={[
          {
            text: "Profil",
            url: "/dashboard",
          },
          {
            text: "Kursus yang diikuti",
            url: "/dashboard/courses",
          },
          // {
          //   text: "Event yang diikuti",
          //   url: "/dashboard/events",
          // },
          // {
          //   text: "Coretan mentor disukai",
          //   url: "/dashboard/drafts",
          // },
        ]}
        link
        chosen={chosen}
      >
        <div>{children}</div>
      </Smallnav>
    </DashboardStyles>
  )
}

export default DashboardComponent
